import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { blue, grey, deepOrange } from "@material-ui/core/colors";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Link } from "gatsby";
import Fade from "react-reveal/Fade";
import SEO from "../components/seo";
import Hani from "../images/hani.png";
import Elizabeth from "../images/elizabeth.png";
import Noah from "../images/noah.png";
import Phil from "../images/phil.png";
import Jessica from "../images/jessica.png";
import Andre from "../images/andre.png";

export const TEAM_MEMBERS = [
  {
    image: Hani,
    name: "Dr Hani Ebrahimi",
    Title: "CEO & Co-founder",
  },
  {
    image: Elizabeth,
    name: "Elizabeth Proctor",
    Title: "Regulatory Affairs Manager",
  },
  {
    image: Noah,
    name: "Noah Elion",
    Title: " VP Business Development",
  },
  {
    image: Phil,
    name: "Phil Risher",
    Title: "Senior Account Executive",
  },
  {
    image: Jessica,
    name: "Jessica Reuter Castrogiovanni",
    Title: "Software QA Manager",
  },
  {
    image: Andre,
    name: "Andre Fox",
    Title: "Senior Developer",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 350,
    width: 250,
    padding: 8,
  },
  profilePhoto: {
    width: 200,
    height: 200,
    margin: "auto",
    display: "block",
  },
  control: {
    padding: theme.spacing(2),
  },
}));

export default () => {
  const classes = useStyles();
  const smMatch = useMediaQuery("(min-width:1100px)");

  return (
    <>
      <SEO
        title="About us"
        description="At LabLog, we&#039;re making the lab a better place and loving every minute of it."
      />

      <Container maxWidth="md">
        <div style={{ lineHeight: "180%", paddingTop: "20px" }}>
          <Box display={smMatch ? "flex" : null} justifyContent="space-between">
            <Box color={grey[900]} mr={1.5} fontSize="1.1em">
              <Box mb={1} fontSize="1.5em" fontWeight="700" color="#3d3d3d">
                Make lab life more organized and more productive.
              </Box>
              <Box>
                From Fortune 500 companies to startup research labs, lab members
                use LabLog to organize and secure their research data and notes.
                LabLog organizes your research data, files, and notes so you can
                focus on what matters most.
              </Box>
              <Box mt={1.5}>
                Improving laboratory efficiency is our mission. We specialize in
                automating laboratory workflows, optimizing digital processes,
                and contextualizing laboratory data and documents.
              </Box>
            </Box>
            <Fade right delay={300}>
              <Box
                bgcolor={deepOrange[700]}
                fontWeight="300"
                ml={1.5}
                mt={8}
                fontSize="1.2em"
                p={5}
                pr={8}
                pt={8}
                color="white"
              >
                At LabLog, we believe there is a better way to keep laboratory
                records: a more secure, less time-consuming way where
                researchers feel confident in putting their trust in a digital
                lab notebook.
              </Box>
            </Fade>
          </Box>
          <Box>
            <Grid
              container
              spacing={3}
              style={{ marginTop: "50px", backgroundColor: "#fff" }}
            >
              <Grid item md style={{ backgroundColor: "#fff" }}>
                <Box>
                  <Box
                    textAlign="left"
                    component="h2"
                    pb={0}
                    mb={0}
                    fontWeight="700"
                    color="#3d3d3d"
                    fontSize="1.5em"
                  >
                    It&#39;s how we built LabLog
                  </Box>
                  <Box
                    textAlign="left"
                    component="h2"
                    pt={0}
                    mt={0}
                    mb={1}
                    fontWeight="700"
                    color="#3d3d3d"
                    fontSize="1.5em"
                  >
                    - not just what we do
                  </Box>
                  <Box
                    color="#3d3d3d"
                    component="p"
                    fontSize="1.1em"
                    textAlign="left"
                  >
                    It&#39;s hard to manage research data, files, and notes
                    collected over decades in a single laboratory. Organizing
                    your notes in a secure, easy-to-access, collaborative
                    solution that integrates with the tools you already use in
                    the lab is even more challenging. We&#39;re here to bridge
                    that gap. Our leadership team is among some of the most
                    experienced leaders in software development for the
                    healthcare and pharmaceutical industry. Collectively we have
                    developed more than a dozen award-winning software tools for
                    laboratory research. That experience helps us push the
                    boundaries of what an Electronic Lab Notebook platform can
                    do for your organization. Learn more about how we do this by
                    browsing our <Link to="/blog"> Blog posts </Link>that
                    highlight user case studies.
                  </Box>
                </Box>
              </Grid>
              <Grid item md>
                <Box>
                  <Box height="5px" width="35px" bgcolor={deepOrange[500]} />
                  <Box
                    textAlign="left"
                    component="h2"
                    mb={1}
                    fontWeight="700"
                    color="#3d3d3d"
                    fontSize="1.5em"
                  >
                    Our story
                  </Box>
                </Box>
                <Box
                  color="#3d3d3d"
                  component="p"
                  fontSize="1em"
                  textAlign="left"
                >
                  LabLog was founded by two former postdoctoral researchers from
                  NIH (National Institutes of Health), Dr. Hani Ebrahimi and Dr.
                  Pamela Gallagher. After years of research experience at the
                  senior level, they found themselves frustrated with a lack of
                  good laboratory documents and workflow management systems.
                  They decided to create LabLog — an Electronic Lab Notebook
                  that helps researchers find more free time in the lab to focus
                  on what matters most. The first step in creating an incredible
                  platform was finding the right team of expert software
                  developers with experience in healthcare and pharmaceutical
                  research software development. The second step was to listen
                  to potential users to understand how an Electronic Lab
                  Notebook can help make lab life easier for them. The third and
                  final step was to vet the product by researchers just like
                  yourself; Every feature is built from the ground up with
                  careful consideration of feedback from research scientists in
                  multiple laboratories.
                </Box>
              </Grid>
            </Grid>
          </Box>
        </div>

        <Box mt={6} display={smMatch ? "flex" : null}>
          <Box bgcolor={blue[100]} pl={6} pt={15} pb={9} pr={15} mb={7}>
            <Box fontWeight="600" fontSize="1.2em">
              Founded
            </Box>
            <Box fontWeight="700" fontSize="2.3em">
              2016
            </Box>
            <Box fontWeight="600" mt={6} fontSize="1.2em">
              Team Members
            </Box>
            <Box fontWeight="700" fontSize="2.3em">
              110
            </Box>
            <Box fontWeight="600" mt={6} fontSize="1.2em">
              Lines of Code
            </Box>
            <Box fontWeight="700" fontSize="2.3em">
              Over 100M
            </Box>
            <Box fontWeight="600" mt={6} fontSize="1.2em">
              Cups of Coffee
            </Box>
            <Box fontWeight="700" fontSize="2.3em">
              1,541,912
            </Box>
          </Box>
          <Box ml={3}>
            <Box fontWeight="600" fontSize="2.3em">
              Meet us!
            </Box>
            <Box fontSize="1em" lineHeight="1.5">
              <p>
                We want to learn more about you and your goals for organizing
                your research notes.
              </p>
              <p>
                <Link to="/request-demo" color="secondary">
                  <strong>Click here</strong>
                </Link>{" "}
                to request a virtual or in-person meeting with us. We can meet
                you in your lab, at our office, or via video conference. Just
                send us a request and we will take care of the rest.
              </p>
            </Box>

            <Fade delay={300}>
              <Box display="flex" mt={3} justifyContent="center">
                <Link to="/request-demo" style={{ textDecoration: "none" }}>
                  <Box
                    border={`1px solid ${deepOrange[800]}`}
                    p={3}
                    fontWeight="600"
                    bgcolor="#ffffff"
                    color={deepOrange[800]}
                    style={{ "&:hover": { color: deepOrange[900] } }}
                  >
                    Let&#39;s Collaborate
                  </Box>
                </Link>
              </Box>
            </Fade>
            <Box fontWeight="600" mt={3.5} fontSize="2.3em">
              Careers
            </Box>
            <Box fontSize="1em" lineHeight="1.5">
              At LabLog we offer high-growth opportunities with a simpler, more
              pleasant, more productive working life. Job openings are posted
              regularly on LinkedIn and this page.
            </Box>
            <Box fontWeight="600" mt={3.5} fontSize="2.3em">
              The LabLog Offices
            </Box>
            <Box fontSize="1em" mt={1.5} lineHeight="1.5">
              <b>United States:</b>
              <br />
              Federal Employer ID Number: 81 - 3947253
              <br />
              1201 Seven Locks Road, Rockville, Maryland 20854
              <br />
              Phone: +1 (833) 4-LabLog
            </Box>
            <Box fontSize="1em" mt={1.5} lineHeight="1.5">
              <b>United Kingdom:</b>
              <br />
              Registered in England No. 13478444. VAT Registration No. GB 384
              5765 51.
              <br />
              71-75 Shelton Street, Covent Garden, London, England, WC2H 9JQ
              <br />
              Phone: 0203 829 2883
            </Box>
          </Box>
        </Box>
        <Box
          height="5px"
          width="35px"
          bgcolor={deepOrange[500]}
          mb={3}
          mt={5}
        />
        <Box mb={2} fontWeight="700" color="#3d3d3d" fontSize="2.5em">
          Meet the senior team
        </Box>
        <Box mb={3.5} fontSize="1.5em">
          Our leadership includes some of the most inventive and experienced
          executives in the healthcare software technology industry.
        </Box>
        <Box mb={5}>
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
              <Grid container justify="center" spacing={3}>
                {TEAM_MEMBERS.map((value) => (
                  <Grid key={value.name} item md={4}>
                    <Paper className={classes.paper}>
                      <img
                        alt={value.name}
                        src={value.image}
                        className={classes.profilePhoto}
                      />
                      <Box
                        color="#3d3d3d"
                        component="p"
                        textAlign="center"
                        fontSize="h6.fontSize"
                      >
                        {value.name}
                        <br />
                        <br />
                        {value.Title}
                      </Box>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};
